<!-- Main view when user is logged in -->
<template>
  <v-app>
    <RentalClothesNavBar />

    <v-main class="ma-4">
      <router-view name="rentalclothes"></router-view>
    </v-main>
    <RentalClothesFooter />
  </v-app>
</template>
<script>
import RentalClothesNavBar from '@/components/rentalclothes/main/RentalClothesNavBar';
import RentalClothesFooter from '@/components/rentalclothes/main/RentalClothesFooter';

export default {
  data() {
    return {
      username: '',
    };
  },
  components: {
    RentalClothesNavBar,
    RentalClothesFooter,
  },
  async created() {
    // if (!this.$store.getters.isLoggedIn) {
    //  this.$router.push('/login');
    // }
    this.username = this.$store.getters.getUser.username;
  },
  methods: {
    logout() {
      this.$store.dispatch('logout');
      this.$router.push('/login');
    },
  },
};
</script>
