<template>
  <nav>
    <v-app-bar color="primary" dark app>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>

      <v-spacer></v-spacer>
      <v-menu offset-y>
        <template v-slot:activator="{ on }">
          <v-btn text dark v-on="on">
            <v-icon left large>mdi-account</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item
            v-for="([title, icon, route], i) in users"
            :key="i"
            :to="route"
            active-class="white"
            link
          >
            <v-list-item-icon><v-icon v-text="icon"></v-icon></v-list-item-icon>
            <v-list-item-title>{{ title }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-btn text @click="logout">
        <v-icon right large>mdi-export</v-icon>
      </v-btn>
    </v-app-bar>
    <v-navigation-drawer v-model="drawer" dark app class="primary" width="300">
      <v-layout column>
        <v-flex class="mt-5" align-left>
          <p class="white--text subheading mt-1 text-center">
            {{ user.userEmail }}
          </p>
        </v-flex>
        <v-flex class="mt-4 mb-4">
          <v-divider color="white"></v-divider>
        </v-flex>
      </v-layout>
      <v-list class="text-left">
        <!--Home link-->
        <v-list-item-group>
          <v-list-item to="/">
            <v-list-item-icon>
              <v-icon>mdi-home</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Etusivulle</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
        <v-list-item-group>
          <v-list-item to="/rental-clothes/add-rental-customer">
            <v-list-item-icon>
              <v-icon>mdi-account-plus</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Lisää asiakkaat</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
        <v-list-item-group>
          <v-list-item to="/rental-clothes/show-rental-customers">
            <v-list-item-icon>
              <v-icon>mdi-account-multiple</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Näytä asiakkaat</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
        <v-list-item-group>
          <v-list-item to="/rental-clothes/add-rental-product">
            <v-list-item-icon>
              <v-icon>mdi-home</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Lisää tuotteet</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
        <v-list-item-group>
          <v-list-item to="/rental-clothes/show-rental-products">
            <v-list-item-icon>
              <v-icon>mdi-home</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Näytä tuotteet</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
        <v-list-item-group>
          <v-list-item to="/rental-clothes/add-rental-clothes">
            <v-list-item-icon>
              <v-icon>mdi-home</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Lisää vuokravaate</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
        <v-list-item-group>
          <v-list-item to="/rental-clothes/show-rental-clothes">
            <v-list-item-icon>
              <v-icon>mdi-home</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Näytä vuokravaateet</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
        <v-list-item-group>
          <v-list-item to="/rental-clothes/show-report">
            <v-list-item-icon>
              <v-icon>mdi-chart-line</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Näytä raportti</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
      <!-- Drop-down -->

      <!-- Drop-down end -->
    </v-navigation-drawer>

    <!--NAvigointi drawer alkaa -->
  </nav>
</template>

<script>
export default {
  data: () => ({
    name: 'navbar',
    user: [],
    drawer: true,
    users: [
      ['Muuta salasana', 'mdi-account', '/rental-clothes/change-password'],
      [
        'Muuta omat tiedot',
        'mdi-account',
        '/rentalclothes/change-user-account',
      ],
    ],
  }),
  created() {
    this.user = this.$store.state.user;
  },
  methods: {
    logout() {
      this.$store.dispatch('setToken', null);
      this.$store.dispatch('setUser', null);
      this.$router.push({
        name: 'login',
      });
    },
  },
};
</script>

<style scoped>
.v-list .v-list-item--active,
.v-list .v-list-item--active .v-icon {
  color: white;
}
</style>
